<template>
  <div class="flix-col-12 flix-col-md-8 flix-col-md-flix-offset-2">
    <h2 class="flix-html-h2">Email-Postfach Zugangsdaten</h2>
    <div class="flix-form-group">
      <div class="flix-alert flix-alert-success">
        <span><b>{{ email }}</b> {{ $t('message.successfullSaved') }}</span>
      </div>
    </div>
    <div class="flix-form-group">
      <a href="#" class="flix-btn flix-btn-danger" @click.prevent="setDelete">{{ $t('message.delete') }}</a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    email: String,
    callback: Function
  },
  data () {
    return {
      variables: this.$getUserVariables()
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    setDelete () {
      this.$flix_post({
        url: 'user_smtp/save',
        data: {
          user: this.variables.user.md5_id
        },
        callback: function (ret) { this.callback(false) }.bind(this)
      })
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
